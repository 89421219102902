import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import FormSubmissionsView from "./packages/common/Forms/FormSubmissions/FormSubmissionsView";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";
import startCase from "lodash/startCase";

import * as buttonVariants from "./theme/buttonVariants";
import { colors, fonts } from "./theme/tailwind";

import {
  FormikInput as Input,
  FormikTextarea as Textarea,
  Select,
  FormikRadio as Radio,
  FormikCheckbox as Checkbox,
  CheckboxGroup,
  File,
} from "./Inputs";

import { Button } from "components/Button";

const config = new Config({
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    PeractoUser(),
    PeractoCommon({ FormSubmissionsView }),
    PeractoCatalogue(),
    PeractoLocations(),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    isTailwind: true,
    theme: EditorTheme,
    colorOptions: compact(
      Object.entries(colors).map(([label, value]) => {
        return {
          label: startCase(label.replaceAll("brand-", "")),
          value,
        };
      })
    ),
    fonts,
    locales: ["en_GB"],
    blocks,
    buttonVariants,
    inputTypes: {
      Input,
      Textarea,
      Select,
      Radio,
      Checkbox,
      CheckboxGroup,
      File,
      Button,
    },
    stylesheets: ["https://use.typekit.net/ahc2yss.css"],
    globalStyles: GlobalStyles,
    headings: [
      {
        model: "h1",
        title: "Huge Heading 1",
        class: "hugeHeading1List",
        view: {
          name: "h1",
          classes: "heading1-huge",
        },
        converterPriority: "high",
      },
    ],
  },
  features: {
    dashboard: {
      tables: true,
    },
    shippingServices: {
      deliveryDays: true,
      conditions: true,
    },
    products: {
      exportProductData: true,
      productVariants: true,
      currencySymbols: {
        euroPrice: "€",
        euroRrp: "€",
        euroSalePrice: "€",
        euroClearancePrice: "€",
      },
      additionalPriceTypes: [
        "euroPrice",
        "euroRrp",
        "euroSalePrice",
        "euroClearancePrice",
      ],
    },
    navigation: {
      readOnlyPaths: true,
    },
    content: {
      indexContent: true,
      productRelationships: {
        article: true,
        element: true,
        page: true,
        template: true,
      },
      reCaptcha: true,
    },
    user: {
      companySettings: true,
      userGroups: true,
      userSettings: true,
    },
  },
  menu,
});

const App = () => (
  <ThemeProvider theme={AdminTheme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
